import React, { useState, useEffect } from 'react';
import "./Style.css";
import { TiLocationOutline } from "react-icons/ti";
import { MdOutlineMail } from "react-icons/md";
import { IoMdPhonePortrait } from "react-icons/io";
import { FaLongArrowAltRight } from "react-icons/fa";
import 'animate.css';
import 'aos/dist/aos.css'
import emailjs from "emailjs-com";
import { HashLink as Link } from 'react-router-hash-link';
import { Helmet } from 'react-helmet';


function Home() {

    const [formError, setFormError] = useState({});

    const handleSubmit = async (e) => {
        e.preventDefault();

        const name = e.target.elements.name.value;
        const email = e.target.elements.email.value;
        const number = e.target.elements.number.value;
        const message = e.target.elements.message.value;

        // Validate form fields
        const errors = {};
        if (!name) errors.name = 'Name is required';
        if (!email) errors.email = 'Email is required';
        if (!number) errors.number = 'Contact number is required';
        if (!message) errors.message = 'Message is required';

        if (Object.keys(errors).length > 0) {
            setFormError(errors);
            return;
        }

        // Send email using EmailJS
        try {
            await emailjs.send('service_gcmne8p', 'template_1keyk08', {
                from_name: name,
                reply_to: email,
                message: message,
                contact_number: number
            }, '9FnobXzLMsHMX5KyZ');

            // Clear form fields after successful submission
            e.target.reset();
            setFormError({});
            alert('Message sent successfully!');
        } catch (error) {
            console.error('Error sending email:', error);
            alert('An error occurred while sending the message. Please try again later.');
        }
    }

    const handleFocus = (e) => {
        setFormError({ ...formError, [e.target.name]: '' });
    }

    return (


        <>

            <Helmet>
                <title>Genie Book</title>
            </Helmet>

            <nav className="navbar navbar-light navbar-expand-lg bg-faded navbar-main-sec sticky-top">
                <div className="container">
                    <a href="/" className="navbar-brand d-flex w-50 mr-auto navbar-logo">
                        <img className='img-fluid' src="Images/navLogo.png" alt="Logo" />
                    </a>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapsingNavbar3"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div
                        className="navbar-collapse collapse w-100 main-nvlnk"
                        id="collapsingNavbar3" >
                        <ul className="navbar-nav dropdown">
                            <li className="nav-item">
                                <a className="nav-link dropbtn" href="#home" role="button">HOME</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link dropbtn" href="#whyeducationGenie" role="button">WHY EDUCATION GENIE</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link dropbtn" href="#contact" role="button">CONTACT US</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>

            <section className='sec-1' id="home">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 sec-1Col-txt animate__animated animate__backInLeft" data-aos="fade-up">
                            <h1>Precise notes for CBSE Science For grades 8th, 9th and 10th</h1>
                        </div>
                        <div className="col-lg-6 sec-1Col-Img animate__animated animate__fadeInDown animate-on-scroll">
                            <img className='img-fluid' src="Images/banner.jpg" alt="Banner" />
                        </div>
                    </div>
                </div>
            </section>

            <section className='sec-2'>
                <div className="container">
                    <h3>Education Genie Books</h3>
                    {/* <h3>Genie Education Book</h3> */}
                    <div className="row sec-2Row-1">
                        <div className="col-lg-4">
                            <div className="booksBg animate__animated animate__fadeInLeft">
                                <img className='img-fluid' src="Images/8th-bookimg-1.png" alt="Book 1" />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="booksBg  animate__animated animate__fadeInUp">
                                <img className='img-fluid' src="Images/8th-bookimg-2.png" alt="Book 2" />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="booksBg animate__animated animate__fadeInRight">
                                <img className='img-fluid ' src="Images/8th-bookimg-3.png" alt="Book 3" />
                            </div>
                        </div>
                    </div>
                    <div className="row sec-2Row-2">
                        <div className="col-lg-4">
                            <div className="booksBg animate__animated animate__fadeInLeft">
                                <img className='img-fluid' src="Images/9th-bookimg-1.png" alt="Book 1" />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="booksBg animate__animated animate__fadeInUp">
                                <img className='img-fluid ' src="Images/9th-bookimg-2.png" alt="Book 2" />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="booksBg animate__animated animate__fadeInRight">
                                <img className='img-fluid ' src="Images/9th-bookimg-3.png" alt="Book 3" />
                            </div>
                        </div>
                    </div>
                    <div className="row sec-2Row-3">
                        <div className="col-lg-4">
                            <div className="booksBg animate__animated animate__fadeInLeft">
                                <img className='img-fluid' src="Images/10th-bookimg-1.png" alt="Book 1" />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="booksBg animate__animated animate__fadeInUp">
                                <img className='img-fluid ' src="Images/10th-bookimg-2.png" alt="Book 2" />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="booksBg animate__animated animate__fadeInRight">
                                <img className='img-fluid ' src="Images/10th-bookimg-3.png" alt="Book 3" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='sec-3' id="whyeducationGenie">
                <div className="container sec-3Maincont">
                    <div className="row sec-3Rowhed">
                        <h2>WHY EDUCATION GENIE</h2>
                        <p>Education Genie has adopted <b>“read  and grasp” psychology of human eyes and brain, and created the structure of the notes based on it.</b></p>
                        <b>Features of these notes which make them stand out among other books available in the market are:</b>
                    </div>
                    <div className="row sec-3RowMaintext">
                        <div className="sec-3RowMaintextContainer">
                            <ul>
                                <li> <FaLongArrowAltRight /> Big and comfortably readable fonts. </li>

                                <li><FaLongArrowAltRight />
                                    The flow of the text is arranged in a single column across the page width with sufficient spacing between the lines to facilitate comfortable reading and clear understanding. </li>

                                <li><FaLongArrowAltRight />
                                    Bold letters used for questions in other books are psychologically irritating.
                                </li>
                                <p> These notesused normal font style and pleasant light blue colour for questions which provides soothing feeling to eyes and brain.</p>

                                <li><FaLongArrowAltRight />
                                    Contrary to other books which use dull papers, very small fonts, congested contents and multiple columns across the width of the page, which produce psychological dullness in the mind and obstructs the flow of information to the brain; </li>

                                <p>education genie uses super white, 100 GSM best quality paper. The combination of  paper quality, big fonts, pleasant colours and appropriate spaces between the lines offer pleasant look and feel which secretes happy hormones in brain and elevates your study session. </p>

                                <li><FaLongArrowAltRight />
                                    Long questions are divided in paragraphs information wise for clear understanding of what is given and what is asked.</li>

                                <li><FaLongArrowAltRight />
                                    The answers are divided in small paragraphs information-wise for clear understanding.</li>

                                <li><FaLongArrowAltRight />
                                    Each question and answer and numerical questions and their solutions have been placed on the same page to maintain the continuity of reading without a break to turn over the page, without interrupting the flow of information to the brain.</li>

                                <li><FaLongArrowAltRight />
                                    For long answers or numerical solutions which do not accommodate on one page, the question and answers are strategically placed on the left -right continuous pages allowing for uninterrupted learning.</li>

                                <li><FaLongArrowAltRight />
                                    Likewise, the diagrams and their explanation are also placed on the   same or continuous left-right pages to help the human scan system to acquire the whole information in one go and reach the brain without interruption.</li>

                                <li><FaLongArrowAltRight />
                                    Most of the diagrams are free hand drawn giving a feel of students’ own  copy and boost their confidence to redraw them.</li>

                                <li><FaLongArrowAltRight />
                                    Most of the diagrams have been described by breaking them intomanageable parts and the flow is explained to redraw them easily.</li>

                                <li><FaLongArrowAltRight />
                                    Numerical formulae of Physics are consolidated on one page chapter wise at the end of the book for quick reference.
                                </li>

                                <li><FaLongArrowAltRight />
                                    Three separate notes are printed for physics, chemistry and biology to keep the binding thin and light weight so they can be wide opened and easy to handle even while lying down.</li>

                                <li><FaLongArrowAltRight />
                                    No information in these notes is taken from out of CBSE/NCERT syllabus and the contents strictly follows the scope of the respective grade.</li>

                                <li><FaLongArrowAltRight />
                                    Contrary to the online contents where answers of question many a time contains the stuff of higher levels based on the knowledge of the scholars.</li>
                            </ul>
                            <hr></hr>
                            <div className="row sec-3Rowvideosec">
                                <h3>Video</h3>
                                <div className="col-lg-12">
                                    <video muted loop controls id='video' className='video-fluid'>
                                        <source src="Images/Mother.mp4" type="video/mp4" />
                                    </video>
                                    <h5>Coming soon : 1) Notes of social science. 2) Notes for grades 5th, 6th and 7th.</h5>
                                </div>
                                <p>Education genie notes are available on all leading e-commerce sites. If ecommerce vendors do not deliver in your town or area, please contact us at info@educationgenie.co.in</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='sec-4' id="contact">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 sec-4contact-deat">
                            <h3>Contact Us</h3>
                            <p><MdOutlineMail /> info@educationgenie.co.in</p>
                        </div>
                        <div className="col-lg-6 sec-4contact-form">
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label htmlFor="name">Your Name</label>
                                    <input type="text" className="form-control" id="name" name="name" onFocus={handleFocus} />
                                    {formError.name && <span className="error">{formError.name}</span>}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="email">Email address</label>
                                    <input type="email" className="form-control" id="email" name="email" onFocus={handleFocus} />
                                    {formError.email && <span className="error">{formError.email}</span>}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="number">Contact Number</label>
                                    <input type="text" className="form-control" id="number" name="number" onFocus={handleFocus} />
                                    {formError.number && <span className="error">{formError.number}</span>}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="message">Message</label>
                                    <textarea className='form-control' id="message" name="message" rows="6" cols="50" onFocus={handleFocus}></textarea>
                                    {formError.message && <span className="error">{formError.message}</span>}
                                </div>
                                <button type="submit" className="btn-primary submit-btns">Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

            <footer className='footer-sec'>
                <div className="container">
                    <div className="row footer-main-row">
                        <div className="col-md-4">
                            <div className="footer-main-text">
                                <h4>About Us</h4>
                                <p>Embark on a path of academic success with Education Genie – where learning meets simplicity.
                                    Our uniquely crafted notes are comprehensive yet concise, and easy-to-understand, offering a perfect, hassle-free alternative for hectic coaching classes and ensuring effective self-study.</p>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="footer-main-text">
                                <h4>Useful Links</h4>
                                <ul className="project-ul">
                                    <li><Link to="/#home">Home</Link></li>
                                    <li><Link to="/#whyeducationGenie">Why Education Genie</Link></li>
                                    <li><Link to="/#contact">Contact Us</Link></li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="footer-main-text">
                                <h4>Contact info</h4>
                                <ul className="contact-list">
                                    <li>
                                        <i className="fa-regular fa-envelope"></i>
                                        <a href="#"> info@educationgenie.co.in</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container copyright">
                    <div className="row">
                        <div className="col-12">
                            <p>@ All Rights Reserved by <span> <Link>Genie</Link> </span> </p>
                        </div>
                    </div>
                </div>

            </footer>
        </>
    );
}

export default Home;
